import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../site.config"

/** components */
import Layout from "../../components/Layout"
import { theme } from "../../components/Theme"

/** dynamic sections */
import HeroTopSection from "../../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../../components/dynamic-sections/RibbonSection"
import AwardsSection from "../../components/dynamic-sections/AwardsSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const OakdaleHistoryPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/locations/oakdale/history/",
          name: "Oakdale History | Norhart",
          image: `${config.siteMetadata.siteUrl}/locations/oakdale/history/oakdale-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Oakdale City History | Norhart"
      description=""
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/locations/oakdale/history/oakdale-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/locations/oakdale/history/oakdale-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Oakdale, MN"
        subtitle="Experience What Oakdale Has To Offer"
        imageTitle="Experience What Oakdale Has To Offer"
        image="/locations/oakdale/history/oakdale-history-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Norhart And Oakdale"
        tagLine="We build more than just apartments, we improve lives"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <AwardsSection
        header="Oakdale Apartments"
        title="We Build More Than Apartments"
        subtitle="We have been called out for being an innovator, setting the bar high, and pushing the industry in a new direction. That's just who we are. Experience the Norhart difference for yourself!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default OakdaleHistoryPage
